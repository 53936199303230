<template>
  <div class="mt-5 mx-5" style="width: 100%">
    <div class="d-flex flex-row" style="justify-content: space-between">
      <span
        style="color: #6038F7; font-size: 20px; font-family: 'Poppins-Medium'"
        >{{ $t("needs") }}</span
      >
      <v-btn
        color="#B5E539"
        small
        @click="onCreateNeed"
        v-if="profileType == 'Agency'"
      >
        Create
      </v-btn>
      <div v-if="profileType == 'Volunteer'" class="d-flex">
        <v-switch
          v-model="filterResponded"
          label="Responded"
          hide-details
          class="mt-0"
          @change="onChangeFilter"
        />
      </div>
    </div>
    <agency-need-card
      :need="need"
      v-for="need in filterResponded ? filteredNeeds : needs"
      :key="need._id"
      class="mb-3"
    />
    <v-card
      v-if="needs.length == 0 && profileType == 'Agency'"
      class="d-flex flex-column align-center mt-5"
    >
      <v-card-title class="mt-5">
        <v-img src="@/assets/svg/no-need.svg" height="200" contain />
      </v-card-title>
      <v-card-title>
        No needs found in your agency
      </v-card-title>
      <v-card-text class="d-flex justify-center mb-3" style="color: #6038f7">
        Create your need and give volunteers the opportunities to help you.
      </v-card-text>
    </v-card>
    <agency-create-need
      :dialog="createDialog"
      :onCloseDialog="onCloseCreateDialog"
    />
    <agency-edit-need
      :item="selected"
      :dialog="editDialog"
      :onCloseDialog="onCloseEditDialog"
    />
    <confirm-delete-need-dialog
      :deleteDialog="deleteDialog"
      :need="selected"
      :onClose="onCloseConfirmDelete"
      :onDeleted="onDeletedNeed"
    />
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ConfirmDeleteNeedDialog from "../../../components/common/ConfirmDeleteNeedDialog.vue";
import AgencyCreateNeed from "./create/AgencyCreateNeed.vue";
import AgencyEditNeed from "./create/AgencyEditNeed.vue";
import AgencyNeedCard from "./AgencyNeedCard.vue";
export default {
  components: {
    AgencyCreateNeed,
    ConfirmDeleteNeedDialog,
    AgencyEditNeed,
    AgencyNeedCard,
  },
  data() {
    return {
      loading: false,
      createDialog: false,
      selected: null,
      filterResponded: false,
      editDialog: false,
      deleteDialog: false,
      snackbar: false,
      snackMessage: null,
      filteredNeeds: [],
    };
  },
  methods: {
    ...mapActions("need", {
      fetchAllNeeds: "fetchAllNeeds",
    }),
    onCreateNeed() {
      this.createDialog = true;
    },
    onCloseCreateDialog() {
      this.createDialog = false;
      this.fetchAllNeeds();
    },
    goToDetails(item) {
      this.$router.push({
        name: "agency-need-details",
        query: {
          _id: item._id,
        },
      });
    },
    onDelete(need) {
      this.selected = need;
      this.deleteDialog = true;
    },
    onDeletedNeed() {
      this.deleteDialog = false;
    },
    onEdit(need) {
      this.selected = need;
      this.editDialog = true;
    },
    onCloseEditDialog() {
      this.editDialog = false;
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
    },
    async onShare(need) {
      this.copyToClipboard(
        process.env.VUE_APP_WEB_URL + "/needs/details?_id=" + need._id
      );
      this.snackbar = true;
      this.snackMessage = "copied share url";
    },
    copyToClipboard(textToCopy) {
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
      } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // here the magic happens
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
        });
      }
    },
    onChangeFilter() {
      if (this.filterResponded) {
        this.filteredNeeds = this.needs.filter((n) => n.response != null);
      }
    },
  },
  computed: {
    ...mapGetters("need", {
      needs: "getAllNeeds",
    }),
    ...mapState("auth", {
      profile: "profile",
      profileType: "type",
    }),
  },
  mounted() {
    this.loading = true;
    this.fetchAllNeeds()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
};
</script>
